import React from 'react'
import Services from "../components/UI/Service/Services"
import Newsletter from '../components/UI/Newsletter';
import SHero from "../components/UI/Service/SHero";
import About from "../components/UI/Service/About";



const Service = () => {
  return (
    <>
      <SHero />
      <About />
      <Services />
      <Newsletter />
    </>
  )
}

export default Service