import {Routes, Route, Navigate } from'react-router-dom'

import Home from "../pages/Home"
import Kontakt from "../pages/Kontakt"
import Preise from "../pages/Preise"
import Service from "../pages/Service"
import Beispiele from '../pages/Beispiele'
import Datenschutz from '../pages/Datenschutz'
import Impressum from '../pages/Impressum'
import Bewerben from '../pages/Bewerben'

const Routers = () => {

  return (
    <Routes>
        <Route  exact path='/' element={<Navigate to="/startseite" />} />
        <Route path='startseite' element={ <Home /> } />
        <Route path='service' element={ <Service /> } />
        <Route path='beispiele' element={ <Beispiele /> } />
        <Route path='preise' element={ <Preise /> } />
        <Route path='kontakt' element={ <Kontakt /> } />
        <Route path='datenschutz' element={ <Datenschutz /> } />
        <Route path='impressum' element={ <Impressum /> } />
        <Route path='bewerben' element={ < Bewerben /> } />
    </Routes>
  )
}

export default Routers