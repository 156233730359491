import React from 'react'
import Ctaone from '../components/UI/Startseite/ctaone';
import Hero from '../components/UI/Startseite/Hero';
import Newsletter from '../components/UI/Newsletter';
import Faq from '../components/UI/Startseite/faq';
import Schritte from '../components/UI/Startseite/Schritte';
import Services from './../components/UI/Service/About'
import Ctaonetwo from '../components/UI/Startseite/ctaonetwo';
import Text from '../components/UI/Startseite/Text';

const Home = () => {
  return (
    <>
    <Hero />
    <Ctaone />
    <Services />
    <Text />
    <Ctaonetwo />
    <Faq />
    </>
  )
}

export default Home