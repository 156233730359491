import React, { useState, useRef } from "react";
import "./faq.css";
import { FiPlus } from "react-icons/fi";
import FAQ from "../../../images/faq.png";

export default function Faq() {
  const [activeIndices, setActiveIndices] = useState([]);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndices((prevActiveIndices) =>
      prevActiveIndices.includes(index)
        ? prevActiveIndices.filter((i) => i !== index)
        : [...prevActiveIndices, index]
    );

    if (contentRefs.current[index]) {
      contentRefs.current[index].style.maxHeight = activeIndices.includes(index)
        ? "0px"
        : `${contentRefs.current[index].scrollHeight}px`;
    }
  };

  const faqs = [
    {
      question: "Was macht ein Stromberater?",
      answer: "Ein Stromberater hilft Ihnen dabei, die besten Stromtarife zu finden und Energieeffizienz zu verbessern."
    },
    {
      question: "Wie kann ich einen Stromberater kontaktieren?",
      answer: "Sie können uns über unser Kontaktformular auf der Webseite oder telefonisch erreichen."
    },
    {
      question: "Welche Vorteile bietet die Beratung?",
      answer: "Unsere Beratung kann Ihnen helfen, Geld zu sparen und umweltfreundlichere Optionen zu wählen."
    },
    {
      question: "Kostet die Beratung etwas?",
      answer: "Die Beratung ist kostenlos. Weitere Dienstleistungen ist für Sie ebenfalls kostenfrei."
    },
    {
      question: "Wie finanziert ihr euch?",
      answer: "Wir haben Kooperationen mit verschiedenen Anbietern und erhalten eine Provision für jeden umgestellten Tarif. Das ändert jedoch nichts an der Qualität unserer Beratung."
    },
    {
      question: "Welche Informationen benötigt der Stromberater von mir?",
      answer: "Um Ihnen den besten Tarif empfehlen zu können, benötigen wir einige Informationen von Ihnen, wie zum Beispiel Ihren aktuellen Stromverbrauch, die Art Ihres Haushalts (Einzelperson, Familie, etc.), und Ihre bisherigen Stromrechnungen. Diese Informationen helfen uns, Ihre Bedürfnisse genau zu analysieren und die besten Optionen für Sie zu finden."
    },
    {
      question: "Wie lange dauert es, bis ich meinen neuen Stromtarif nutzen kann?",
      answer: "Die Umstellung auf einen neuen Stromtarif dauert in der Regel zwischen zwei bis vier Wochen, je nach Anbieter. Wir kümmern uns um den gesamten Wechselprozess, sodass Sie sich um nichts kümmern müssen."
    },
    {
      question: "Kann ich meinen Stromanbieter jederzeit wechseln?",
      answer: "Ja, Sie können Ihren Stromanbieter in der Regel jederzeit wechseln. Es gibt jedoch oft bestimmte Kündigungsfristen, die Sie beachten müssen. Unser Team hilft Ihnen dabei, den optimalen Zeitpunkt für den Wechsel zu finden und alle Formalitäten zu erledigen."
    }
  ];

  return (
    <section className="faq-top">
      <div className="container">
        <div className="about__wrapper">
          <div className="faq__img">
            <img src={FAQ} alt="Schritte" />
          </div>
          <div className="faq">
            {faqs.map((faq, index) => (
              <div className="faq-sec" key={index}>
                <button
                  className={`question-section ${activeIndices.includes(index) ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="faq-qa">
                    <div className="question-align">
                      <h4 className="question-style">
                        {faq.question}
                      </h4>
                      <FiPlus
                        className={
                          activeIndices.includes(index) ? `question-icon rotate` : `question-icon`
                        }
                      />
                    </div>
                    <div
                      ref={(el) => (contentRefs.current[index] = el)}
                      className={activeIndices.includes(index) ? `answer answer-divider` : `answer`}
                      style={{ maxHeight: "0px", overflow: "hidden", transition: "max-height 0.6s ease, padding 0.6s ease" }}
                    >
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
