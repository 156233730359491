// ContactForm.js
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';


const ContactForm = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [formType, setFormType] = useState('beratung');

  const onSubmit = (data) => {
    emailjs.sendForm('service_lnge1mk', 'template_wlkjb71', '#contact-form', 'IpBTb2uiWc_GQdQDK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
<section className='container form-bg'>
<form className=''>
      <div>
        <label>Grund:</label> <br/>
        <button type="button" className='btn-gl' onClick={() => setFormType('beratung')}>Beratung</button>
        <button type="button" className='btn-gl' onClick={() => setFormType('bewerbung')}>Bewerbung</button>
      </div>

      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Name:</label>
          <input name="name" placeholder='Max' {...register("name", { required: true })} />
          {errors.name && <span>Dieses Feld ist erforderlich</span>}
        </div>

        <div>
          <label>Nachname:</label>
          <input name="nachname" placeholder='Mustermann' {...register("nachname", { required: true })} />
          {errors.nachname && <span>Dieses Feld ist erforderlich</span>}
        </div>

        <div>
          <label>Email:</label>
          <input name="email" type="email" placeholder='Mustermann@Outlook.de' {...register("email", { required: true })} />
          {errors.email && <span>Dieses Feld ist erforderlich</span>}
        </div>

        <div>
          <label>Telefonnummer:</label>
          <input name="telefonnummer" type="number" placeholder='0123 4567890' {...register("telefonnummer", { required: true })} />
          {errors.telefonnummer && <span>Dieses Feld ist erforderlich und darf nur Zahlen enthalten</span>}
        </div>

        {formType === 'beratung' && (
          <>
            <div>
              <label>Postleitzahl:</label>
              <input name="postleitzahl" type="number" placeholder='12345' {...register("postleitzahl", { required: true })} />
              {errors.postleitzahl && <span>Dieses Feld ist erforderlich und darf nur Zahlen enthalten</span>}
            </div>
            <div>
              <label>Jetziger Anbieter:</label>
              <input name="anbieter" placeholder='EnBW Komfort 100' {...register("anbieter", { required: true })} />
              {errors.anbieter && <span>Dieses Feld ist erforderlich</span>}
            </div>
            <div>
              <label>KW/h Verbrauch:</label>
              <input name="verbrauch" type="number" placeholder='5500' {...register("verbrauch", { required: true, min: 0 })} />
              {errors.verbrauch && <span>Dieses Feld ist erforderlich und muss eine positive Zahl sein</span>}
            </div>
            <div>
              <label>KW/h Preis:</label>
              <input name="preis" type="number" step="0.01" placeholder='41.17' {...register("preis", { required: true, min: 0 })} />
              {errors.preis && <span>Dieses Feld ist erforderlich und muss eine positive Zahl sein</span>}
            </div>
          </>
        )}

        {formType === 'bewerbung' && (
          <div>
            <label>Nachricht:</label>
            <textarea name="nachricht" placeholder='Was macht dich besonders?' {...register("nachricht", { required: false })}></textarea>
            {errors.nachricht && <span>Dieses Feld ist erforderlich</span>}
          </div>
        )}

        <button type="submit" className='btn-gl'>Senden</button>
      </form>
    </form>
</section>
  );
};

export default ContactForm;
