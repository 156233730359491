import React from "react";
import "./newsletter.css";

const Newsletter = () => {
  return (
    <section className="newsletter">
      <div className="container">
        <div className="newsletter__wrapper">
          <div className="newsletter__content">
            <h6 className="subtitle">Newsletter</h6>
            <h2>
              Bleibe auf dem <span className="highlight">Laufenden um nichts </span> weiteres mehr zu verpassen!
            </h2>
          </div>

          <div className="newsletter__form">
            <input type="email" placeholder="Email" />
            <button className="primary__btn">
              Abonniere jetzt!
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
