import React from "react";
import "./services.css";

const serviceData = [
  {
    icon: "ri-number-1",
    title: "Show 1",
    link: "https://show1.q-tech.dev",
  },
  {
    icon: "ri-number-2",
    title: "Show 2",
    link: "https://show2.q-tech.dev",
  },
  {
    icon: "ri-number-3",
    title: "Show 3",
    link: "https://show3.q-tech.dev",
  },

  {
    icon: "ri-number-4",
    title: "Show 4",
    link: "https://show4.q-tech.dev",
  },

  {
    icon: "ri-number-5",
    title: "Show 5",
    link: "https://show5.q-tech.dev",
  },
];

function reveal() {
  var reveals = document.querySelectorAll(".animation");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-contentb">
          <h6 className="subtitle">Unsere Beispiele</h6>
          <h2>Lassen sie sich Inspirieren</h2>
          <h2 className="highlight"> </h2>
        </div>

        <div className="service__item-wrapper3 animation">
          {serviceData.map((item, index) => (
            <a href={item.link} className="services__item3" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
            </a>
          ))}
        </div>
{/*        <div className="service__item-wrapper1 animation">
          {serviceData1.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
          </div> */}
      </div>
    </section>
  );
};

export default Services;
