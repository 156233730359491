// src/cta.css
import React from "react";
import "./cta.css";
import { NavLink } from "react-router-dom";

const CTA = () => {
  return (
    <div className="cta-section">
      <div className="cta-content">
        <h2>Werde Teil unseres Teams</h2>
        <p>
          Nutze die Gelegenheit und werde Teil unseres dynamischen Teams. Wir freuen uns auf deine Bewerbung!
        </p>
        <NavLink to="/kontakt" >
        <button className="cta-button">Jetzt bewerben</button>
        </NavLink>
      </div>
    </div>
  );
};

export default CTA;
