// src/EnergySavings.jsx
import React from "react";
import "./text.css";

const EnergySavings = () => {
  return (
    <div className="energy-background">
      <div className="energy-savings-container">
        <h1>
          Stromkosten sind unvermeidlich,
          <br /> aber sie können optimiert werden.
        </h1>
        <div className="text-blocks">
            <p>
              Unsere Mission ist es, unseren Kunden dabei zu helfen, Energiekosten
              zu sparen und gleichzeitig umweltfreundliche Entscheidungen zu
              treffen. Wir bieten umfassende Beratung und maßgeschneiderte
              Lösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind.
            </p>
            <p>
              Unser Team besteht aus erfahrenen Energieberatern, die sich leidenschaftlich
              für Nachhaltigkeit und Effizienz einsetzen. Mit unserem Fachwissen und unserer
              Erfahrung unterstützen wir Sie dabei, die besten Entscheidungen für Ihre
              Energieversorgung zu treffen.
            </p>
            <p>
              Haben Sie Fragen oder möchten Sie mehr über unsere Dienstleistungen erfahren?
              Zögern Sie nicht, uns zu kontaktieren. Wir sind hier, um Ihnen zu helfen.
            </p>
        </div>
      </div>
    </div>
  );
};

export default EnergySavings;
