import './App.css';
import React, { useState, useEffect } from "react";
import Layout from './components/Layout/Layout';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter } from "react-router-dom";


function App() {

  const [theme, setTheme] = useState("");

  const toggleTheme = () => {
    theme === "" ? setTheme("light-theme") : setTheme("");
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
  <>

    <BrowserRouter>
    <Layout toggleTheme={toggleTheme}></Layout>

    <CookieConsent
        location="bottom"
        buttonText="Bestätigen"
        cookieName="myAwesomeCookieName2"
        style={{ background: "var(--btn-secondary-bg)", color: "var(--small-text-color)" }}
        buttonStyle={{ color: "var(--btn-primary-bg)", background: "var(--color-primary)", border: "1px solid var(--btn-primary-bg)", fontSize: "13px" }}
        expires={15}
        >
        Diese Website verwendet Cookies und Analytics, um die Benutzererfahrung zu verbessern.{" "}
    </CookieConsent>

    </BrowserRouter>

   </>
  );
}

export default App;
