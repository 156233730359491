import React from 'react'
import "./pricing.css"

const Pricing = () => {
  return (
    <section id="">
        <div className="container">
            <div className="body">
                <div className="top-content">
                    <h2>Einmalige Kosten </h2>
                    <h2 className="highlight"> der verschiedenen Frontend Pakete</h2>
                </div>
                <div className="pricing-table">
                    <div className="pricing-card">
                        <h3 className="pricing-card-header">One Page</h3>
                        <div className="price">+150<sup>€</sup></div>
                        <ul>
                            <li><strong>1</strong> Seite</li>
                            <li><strong></strong> Individuelles Design</li>
                            <li><strong></strong> Responsive (für alle Endgeräte)</li>
                            <li><strong></strong> Google Maps & Kontaktformular</li>
                        </ul>
                    </div>

                    <div className="pricing-card">
                        <h3 className="pricing-card-header">Starter</h3>
                        <div className="price">+450<sup>€</sup></div>
                        <ul>
                            <li><strong>5</strong> Seiten</li>
                            <li><strong></strong> Individuelles Design</li>
                            <li><strong></strong> Responsive (für alle Endgeräte)</li>
                            <li><strong></strong> Google Maps & Kontaktformular</li>
                            <li><strong></strong> Textänderungen inklusive</li>
                            <li><strong></strong> Suchmaschinenoptimiert (SEO)</li>
                        </ul>
                    </div>

                    <div className="pricing-card">
                        <h3 className="pricing-card-header">Buinsess</h3>
                        <div className="price">Individuell</div>
                        <ul>
                            <li><strong> &gt; 10</strong> Seiten</li>
                            <li><strong></strong> Individuelles Design</li>
                            <li><strong></strong> Responsive (für alle Endgeräte)</li>
                            <li><strong></strong> Google Maps & Kontaktformular</li>
                            <li><strong></strong> Textänderungen inklusive</li>
                            <li><strong></strong> Suchmaschinenoptimiert (SEO)</li>
                            <li><strong></strong> Content Management System (CMS)</li>
                            <li><strong></strong> Inklusive Blog / Archiv</li>
                            <li><strong></strong> Zweisprachig</li>
                        </ul>
                    </div>
                </div>
                {/*<div className="pricing-table">
                    <div className="pricing-card">
                            <h3 className="pricing-card-header">E-Commerce Starter</h3>
                            <div className="price"><sup>€</sup>15<span>/MO</span></div>
                            <ul>
                                <li><strong>3</strong> Websites</li>
                                <li><strong>20 GB</strong> SSD</li>
                                <li><strong>1</strong> Domain Name</li>
                                <li><strong>5</strong> Email</li>
                                <li><strong>1</strong> CPU & RAM</li>
                            </ul>
                        </div>

                        <div className="pricing-card">
                            <h3 className="pricing-card-header">E-Commerce Buisness</h3>
                            <div className="price"><sup>€</sup>15<span>/MO</span></div>
                            <ul>
                                <li><strong>3</strong> Websites</li>
                                <li><strong>20 GB</strong> SSD</li>
                                <li><strong>1</strong> Domain Name</li>
                                <li><strong>5</strong> Email</li>
                                <li><strong>1</strong> CPU & RAM</li>
                            </ul>
                        </div>
                    </div>*/}
            </div>
        </div>
    </section>
  )
}

export default Pricing