import React from 'react'
import Hero from "../components/UI//Kontakt/Hero-kontakt"
import Kontakt1 from "../components/UI//Kontakt/Form-kontakt"
import Newsletter from '../components/UI/Newsletter';
import Form from "./../components/UI/Kontakt/Form"


const Kontakt = () => {
  return (
    <>
    <Hero />
    <Kontakt1 />
    </>
  )
}

export default Kontakt