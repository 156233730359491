import React from "react";
import { Link } from "react-router-dom";
import "./hero.css";

import QTDService from "../../../images/QTD-Service.png";

const Hero = () => {

  return (
    <section className="hero__section" id="home">
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
            <div className="text23">
              <h2 className="text23">Full-Service</h2>
              <h2 className="highlight">Entwicklung</h2>
            </div>
            <p className="description">
            Die Entwicklung von indivduellen Anwendungen stellt unsere Kernkompetenz dar.
            Wir können den gesamten Entwicklungsprozess, von der Analyse von Anforderungen
            bis zum Betrieb der Anwendung in der Cloud, inhouse abdecken.
            </p>
          </div>

          <div className="hero__img">
            <img
              src={QTDService}
              alt="hero-img"
              />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
