import React from "react";
import "./services.css";

const serviceData = [
  {
    icon: "ri-code-s-slash-line",
    title: "Web Entwicklung",
    desc: "Unsere Entwicklungsmethoden: klassische Webentwicklung, responsives Webdesign und progressive Web-Apps.",
  },
  {
    icon: "ri-apps-line",
    title: "App Entwicklung",
    desc: "Wir entwickeln maßgeschneiderte Apps kompromisslos und professionell. Die Planung, Entwicklung und Betreuung Ihrer App wird von unserem Team in Deutschland durchgeführt.",
  },
  {
    icon: "ri-database-line",
    title: "Blockchain",
    desc: "Identifizierung und Bewertung geeigneter Anwendungsfälle für Blockchain sowie Auswahl der passenden technologischen Infrastruktur.",
  },

  {
    icon: "ri-store-line",
    title: "Online Shop",
    desc: "Hierfür arbeiten wir mit verschienden Content-Managment-Systemen um ihr Online Shop zu verwalten.",
  },
];

const serviceData1 = [
  {
    icon: "ri-server-line",
    title: "Hosting",
    desc: "Es stehen morderne dedizierte Server zur Verfügung, um Ihr digitales Produkt zu hosten.",
  },

  {
    icon: "ri-dashboard-line",
    title: "CMS",
    desc: "Durch unsere Erfahung in Content-Managment-Systemen können wir verschiedene Möglichkeiten für E-Commerce und Blogs anbieten.",
  },

  {
    icon: "ri-shield-check-line",
    title: "Cybersecurity",
    desc: "Durch eigens entiwckelte Testabläufe im Bereich Sicherheit, sind wir in der Lage ihr Produkt vor ungewollten zutritt zu schützen.",
  },
  {
    icon: "ri-advertisement-line",
    title: "Online Marketing",
    desc: "Wunschbasierte Erstellung einer maßgeschneiderten Marketingstrategie im bereich SEO & SEM, Ads und Socialmedia.",
  },
];

function reveal() {
  var reveals = document.querySelectorAll(".animation");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Unser Service</h6>
          <h2>Wir entwickeln ihr Digitales Produkt.</h2>
          <h2 className="highlight"> </h2>
        </div>

        <div className="service__item-wrapper animation">
          {serviceData.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
        <div className="service__item-wrapper1 animation">
          {serviceData1.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
          </div>
      </div>
    </section>
  );
};

export default Services;
