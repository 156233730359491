import React from 'react'
import BHero from './../components/UI/Beispiele/BHero'
import Service from './../components/UI/Beispiele/Services'

const Beispiele = () => {
  return (
    <>
    <BHero />
    <Service />
    </>
  )
}

export default Beispiele