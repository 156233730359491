import React from 'react'
import Pricing1 from '../components/UI/Preise/Pricing1';
import Pricing from '../components/UI/Preise/Pricing';
import Newsletter from '../components/UI/Newsletter';
import PHero from "../components/UI/Preise/PHero";



const Preise = () => {
  return (
    <>
    <PHero />
    <Pricing1 />
    <Pricing />
    <Newsletter />
    </>
  )
}

export default Preise