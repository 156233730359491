import React from "react";
import { Link } from "react-router-dom";
import "./hero.css";

import IMGB from "../../../images/img-beispiele.webp";

const Hero = () => {

  return (
    <section className="hero__section" id="home">
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
            <div className="text23">
              <h2 className="text23">Beispiel</h2>
              <h2 className="highlight">Webseiten</h2>
            </div>
            <p className="description">
            Durch das Durchstöbern unserer Beispiele können Sie sich ein Bild davon machen, wie eine Webseite aussehen könnte und welche Elemente und Funktionen Sie ansprechen. Wir möchten Ihnen dabei helfen, die besten Lösungen für Ihre individuellen Anforderungen zu finden und Ihnen eine maßgeschneiderte Webseite zu erstellen, die Ihre Ziele erreicht und Ihre Marke zum Strahlen bringt.
            </p>
          </div>

          <div className="hero__img">
            <img
              src={IMGB}
              alt="hero-img"
              />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
