import React from "react";

const Impressum = () => {
  return (
    <section>
      <div className="container">
        <div className="services__top-content1">
          <h6 className="subtitle">Angaben gemäß § 5 TMG</h6>
          <h1>Impressum</h1>
        </div>
        <div className="card__container">
          <div className="wrapper content">
            <div className="card description">
              <p>
                Qualified Energy Experts <br />
                Vertreten durch: Serhan Cumhur Öztürk <br />
                Adresse: Berlinerstraße 4,<br/>
                Ort: 73728 Esslingen am Neckar
                <br />
                Telefon: +49 15561 02 11 69 <br />
                E-Mail: Beratung@q-energyexperts.de
                <br />
                Website: https://Q-EnergyExperts.de
                <br />
                {/* Umsatzsteuer-Identifikationsnummer: DE123456789 */}
                <br /> Verantwortlich für den Inhalt: Serhan Cumhur Öztürk
                <br />
                <br /> Haftungshinweis: Trotz sorgfältiger inhaltlicher
                Kontrolle übernehmen wir keine Haftung für die Inhalte externer
                Links. Für den Inhalt der verlinkten Seiten sind ausschließlich
                deren Betreiber verantwortlich.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Impressum;
