import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { FiInstagram } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";

import Form from "./Form";

/* Author = Serhan Cumhur Öztürk, Company´s: Q-Tech.dev, Q-Energy Experts, Q-Reserve Osmose, Q-Future*/

const Contact = () => {

  return (
    <section className="contact-form">
      <div className="container">
        <div className="container__text">
          <h6 className="subtitle">Kontaktformular</h6>
          <h2>
            Unsere <span className="highlight"> Kontaktdaten</span>
          </h2>
        </div>

        <div className="container__header">
          <div className="contact__options">
            <div className="container__top">
              <article className="contact__option">
                <MdOutlineEmail className="contact__options-icon" />
                <div className="contact__info">
                  <h4>Email</h4>
                  <h5>Beratung@Q-EnergyExperts.de</h5>
                  <a
                    href="mailto:Beratung@Q-energyexperts.de"
                    target="_blank"
                    rel="noreferrer"
                    className="contact_atext"
                  >
                    Sende eine Nachricht
                  </a>
                </div>
              </article>
            </div>

            <div className="container__top">
              <article className="contact__option">
                <FiInstagram className="contact__options-icon" />
                <div className="contact__info">
                  <h4>Instagram</h4>
                  <h5 className="contact_itext">Q-Energy Experts</h5>
                  <a
                    href="https://www.instagram.com/q_energyexperts.de/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sende eine Nachricht
                  </a>
                </div>
              </article>
            </div>
            <div className="container__top">
              <article className="contact__option">
                <BsWhatsapp className="contact__options-icon" />
                <div className="contact__info">
                  <h4>Whatsapp</h4>
                  <h5 className="contact_itext">+49 15561 02 11 69 </h5>
                  <a
                    href="https://wa.me/4915561021169 "
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sende eine Nachricht
                  </a>
                </div>
              </article>
            </div>
          </div>
          <br/>
        <Form />
        </div>
      </div>
    </section>
  );
};

export default Contact;
