import React from "react";
import "./footer.css";

import { Link } from "react-router-dom";
import QTechLogo from "../../images/logoQee.png";

const quickLinks01 = [
  {
    path: "https://Q-Future.org",
    display: "Q-Future",
  },
  {
    path: "https://Q-Tech.dev",
    display: "Q-Tech Dev",
  },
  {
    path: "https://Q-RO.de",
    display: "Q-RO",
  },
];

const quickLinks02 = [
  {
    path: "https://goo.gl/maps/dzBPrjNQjCV4r6Hs5",
    display: "Q-Energy Experts",
  },
  {
    path: "https://goo.gl/maps/dzBPrjNQjCV4r6Hs5",
    display: "Berlinerstr. 4",
  },
  {
    path: "https://goo.gl/maps/dzBPrjNQjCV4r6Hs5",
    display: "73728 Esslingen am Neckar",
  },
];

const quickLinks03 = [
  {
    path: "impressum",
    display: "Impressum",
  },
  {
    path: "datenschutz",
    display: "Datenschutz",
  },
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo">
          <div className="logo" to="startseite">
            <Link to="/startseite">
              <img src={QTechLogo} alt="Q-Tech.dev logo" className="navbar-logo-img1" />
            </Link>
          </div>
            <p className="small__text description">
            Ihr vertrauenswürdiger Partner für Energieoptimierung
            </p>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Links</h3>
            <ul className="quick__links">
              {quickLinks01.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path} target="_blank">{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Anschrift</h3>
            <ul className="quick__links">
              {quickLinks02.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Rechtliches</h3>
            <ul className="quick__links">
              {quickLinks03.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

        </div>
        <p className="copyright">
          Copyright {year}, Q-Energy Experts. Alle Rechte vorbehalten.{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
