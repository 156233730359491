import React from "react";
import { Link } from "react-router-dom";
import "./hero.css";


const Hero = () => {

  return (
    <section className="hero" id="home">
      <div className="hero-content">
        <h1	className="hero-title">Sparen Sie Geld und Energie mit <br/> <span className="hero-title-highlight">Q-Energy Experts</span>
        <br/>
        <br/>
        <p className="hero-title-sub">Ihr vertrauenswürdiger Partner für Energieoptimierung</p>
        </h1>
        <br/>
        <br/>
        <Link to="/kontakt" className="btn-gl">Jetzt Beratung anfordern</Link>
      </div>
    </section>
  );
};

export default Hero;
