import React from "react";
import { Link } from "react-router-dom";
import "./hero.css";

import QTDPreis from "../../../images/preis.png";

const Hero = () => {

  return (
    <section className="hero__section" id="home">
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
            <div className="text23">
              <h2 className="text23">Preis</h2>
              <h2 className="highlight">Gestaltung</h2>
            </div>
            <p className="description">
            Es gibt verschiedene Arten von Websites, die unterschiedlichen Aufwand erfordern. Überlegen Sie zuerst, welchen Zweck Ihre Website erfüllen soll und welche Inhalte Sie langfristig präsentieren wollen. Kontaktieren Sie uns für eine kostenlose Beratung, falls Sie noch unsicher sind.
            </p>
          </div>

          <div className="hero__img">
            <img
              src={QTDPreis}
              alt="hero-img"
              />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
