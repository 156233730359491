import React from "react";
import "./contact.css";

import Kontakt from "../../../images/kontakt.jpg";

const Hero4 = () => {

  return (
    <section className="hero__section" id="home">
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
            <div>
              <h2>Kontaktieren</h2>
              <h2 className="highlight"> Sie uns</h2>
            </div>
            <p className="description">
              Haben Sie Fragen zu unseren Energieberatungsleistungen oder möchten Sie wissen, wie wir Ihnen helfen können, den besten Stromtarif zu finden? Zögern Sie nicht, uns zu kontaktieren. Unser Team ist schnell für Sie da und unterstützt Sie kompetent bei allen Anliegen rund um Ihre Energieversorgung.
            </p>
          </div>

          <div className="kontakt__img">
            <img
              src={Kontakt}
              alt="hero-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero4;
