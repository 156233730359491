// src/About.jsx
import React from "react";
import "./about.css";
import aboutImg from "../../../images/schritte3.png";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="top-content">
          <h6 className="subtitle"></h6>
          <h2>Die Vorgehensweise</h2>
          <h2 className="highlight">In 3 Schritten</h2>
        </div>

        <div className="about__wrapper">
          <div className="about__img">
            <img src={aboutImg} alt="Schritte" />
          </div>
          <div className="about__content">
            <div className="choose__item-wrapper">
              <div className="choose__us-item reveal fade-left">
                <span className="choose__us-icon">
                  <i className="ri-number-1"></i>
                </span>
                <div className="choose__us-header">
                  <h4 className="choose__us-title highlight">Kontakt</h4>
                  <p className="description">
                    Sie Kontaktieren uns über das Kontaktformular oder per
                    E-Mail.
                  </p>
                </div>
              </div>
              <div className="choose__us-item reveal fade-left1">
                <span className="choose__us-icon">
                  <i className="ri-number-2"></i>
                </span>
                <div>
                  <h4 className="choose__us-title highlight">Beratung</h4>
                  <p className="description">
                    Wir Beraten und finden den passenden Anbieter für Sie!
                  </p>
                </div>
              </div>
              <div className="choose__us-item reveal fade-left2">
                <span className="choose__us-icon">
                  <i className="ri-number-3"></i>
                </span>
                <div>
                  <h4 className="choose__us-title highlight">Wechsel</h4>
                  <p className="description">
                    Wir setzten alles weitere gemeinsam in die Wege.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
